import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import MoreStoriesList from 'sections/common/MoreStoriesList';
import Icon from 'components/UI/Icon';

import useMoreArticles from 'hooks/useMoreArticles';

import * as styles from './moreStoriesSec.module.scss';

const MoreStoriesSec = ({
  tag,
  id = '',
  title,
  allPosts,
  reading,
  additionalLink = null,
}) => {
  const articlesToDisplay = useMoreArticles({ tag, id, allPosts });

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.head)}>
            {articlesToDisplay.length <= 0 ? (
              <></>
            ) : (
              <>
                {title ? (
                  <h3 className={classnames(styles.title, 'scr-h3')}>
                    {title}
                  </h3>
                ) : null}
              </>
            )}

            {additionalLink ? (
              <Link
                to={additionalLink.url}
                className={classnames(
                  styles.additionalLink,
                  'scr-blue scr-link'
                )}
                id="link-to-page-template"
              >
                {additionalLink.text}
                &#160; <Icon name="arrow-right" />
              </Link>
            ) : null}
          </div>

          {articlesToDisplay.length <= 0 ? (
            <></>
          ) : (
            <MoreStoriesList
              // tag={tag}
              id={id}
              list={articlesToDisplay}
              reading={reading}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default MoreStoriesSec;
