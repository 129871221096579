import React from 'react';
import classnames from 'classnames';

import Card from 'components/CardArticle';

import * as styles from './moreStories.module.scss';

const MoreStories = ({ list = [], reading = false, btnOff = false }) => {
  return (
    <>
      {list.length > 0 ? (
        <ul className={classnames(styles.list, { [styles.reading]: reading })}>
          {list.map((card) => {
            return (
              <li key={card.id}>
                <Card data={card} reading={reading} btnOff={btnOff} />
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};

export default MoreStories;
