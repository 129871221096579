import React from 'react';
import { Formik, Form } from 'formik';

import * as styles from './form-elem.module.scss';
import validationSchema from './validationSchema';
import Input from 'components/UI/InputFormik';
import InputPhone from 'components/UI/InputPhoneFormik';
import TextAreaFormik from 'components/UI/TextAreaFormik';
// import Checkbox from "components/UI/Checkbox";
import Button from 'components/UI/Button';

const FormElem = ({ data, onSubmit }) => {
  const {
    letsTalkFormNameInputLabel,
    letsTalkFormLastNameInputLabel,
    letsTalkFormCompanyNameInputLabel,
    letsTalkFormEmailInputLabel,
    letsTalkFormPhoneInputLabel,
    letsTalkFormCommentInputLabel,
    letsTalkFormCommentInputPlaceholder,
    letsTalkFormSubmitButton,
  } = data;

  const LABELS = {
    first_name: letsTalkFormNameInputLabel,
    last_name: letsTalkFormLastNameInputLabel,
    company_name: letsTalkFormCompanyNameInputLabel,
    email: letsTalkFormEmailInputLabel,
    phone: letsTalkFormPhoneInputLabel,
    comment_label: letsTalkFormCommentInputLabel,
    comment_placeholder: letsTalkFormCommentInputPlaceholder,

    // satisfaction:
    //   inActionFormAdditionalCheckboxes[0].inActionFormAdditionalCheckboxesItem,
    // engagement:
    //   inActionFormAdditionalCheckboxes[1].inActionFormAdditionalCheckboxesItem,
    // other:
    //   inActionFormAdditionalCheckboxes[2].inActionFormAdditionalCheckboxesItem,
  };

  const labels = LABELS;

  const handleSubmit = (values) => {
    // TODO:
    // const checkboxFields = ["engagement", "other", "satisfaction"];
    // const result = Object.entries(values)
    //   .filter(([key, value]) => !checkboxFields.includes(key) || value)
    //   .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    if (onSubmit) onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
      }}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleSubmit(values);
        }, 400);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className={styles.form} noValidate>
            <div className={styles.form_flex}>
              <Input
                name="first_name"
                type="text"
                label={labels.first_name}
                requiredField={true}
                className={styles.form_field}
              />
              <Input
                name="last_name"
                type="text"
                label={labels.last_name}
                requiredField={true}
                className={styles.form_field}
              />
            </div>

            <Input
              name="company_name"
              type="text"
              label={labels.company_name}
              requiredField={true}
              className={styles.form_field}
            />

            <Input
              name="email"
              type="text"
              label={labels.email}
              requiredField={true}
              className={styles.form_field}
            />

            <InputPhone
              name="phone"
              type="tel"
              label={labels.phone}
              requiredField={true}
              className={styles.form_field}
            />

            <TextAreaFormik
              name="comment"
              // type="tel"
              // rows="30"
              label={labels.comment_label}
              placeholder={labels.comment_placeholder}
              requiredField={false}
              className={styles.form_field}
            />

            {/* <p className="scr-txt-strong">{inActionFormAdditionalTitle}</p>
            <Checkbox name="satisfaction" className={styles.form_checkbox}>
              {labels.satisfaction}
            </Checkbox>
            <Checkbox name="engagement" className={styles.form_checkbox}>
              {labels.engagement}
            </Checkbox>
            <Checkbox name="other" className={styles.form_checkbox}>
              {labels.other}
            </Checkbox> */}

            <Button type="submit" className={styles.form_submit}>
              {letsTalkFormSubmitButton}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormElem;
