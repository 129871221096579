const LetsTalkForm = {
  letsTalkFormTitle: 'Let’s talk then:',
  letsTalkFormNameInputLabel: 'First Name',
  letsTalkFormLastNameInputLabel: 'Last Name',
  letsTalkFormEmailInputLabel: 'Work email',
  letsTalkFormPhoneInputLabel: 'Phone Number',
  letsTalkFormCommentInputLabel: 'comment',
  letsTalkFormCommentInputPlaceholder: 'Briefly describe your idea...',
  letsTalkFormSubmitButton: 'Submit',
  letsTalkFormSuccessTitle: 'Thanks for reaching out!',
  letsTalkFormSuccessSubtitle: 'One of our team members will be in touch with you within the next business day.',
};

export default LetsTalkForm;