import React, { useState } from 'react';
import classnames from 'classnames';
import { useWindowHeight } from '@react-hook/window-size';

import { SendForm } from 'api/forms';

import FormElem from './components/FormElem';
import Success from './components/Success';

import useOptions from 'hooks/graphql/useOptions';
import LetsTalkFormData from 'mocks/customer-stories/lets-talk-form';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import * as styles from './letsTalkForm.module.scss';

const RequestDemoForm = ({ customData }) => {
  const [seccess, setSuccess] = useState(false);
  const options = useOptions();
  const windowHeight = useWindowHeight();
  const deviceState = useDeviceState();

  const { letsTalkFormTitle } = LetsTalkFormData;

  const { letsTalkForm } = options.letsTalkForm;

  const handleSubmit = (values) => {
    SendForm(values, 'lets_talk_form');
    console.info(values, 'lets_talk_form');

    setTimeout(() => {
      deviceState === MOBILE_KEY && window.scrollBy(0, (windowHeight / 2) * -1);

      setSuccess(!seccess);
    }, 400);
  };

  return (
    <div>
      {!seccess ? (
        <>
          <h3 className={classnames(styles.title, 'scr-h3 scr-white')}>
            {letsTalkFormTitle}
          </h3>

          <FormElem
            data={letsTalkForm}
            onSubmit={(values) => handleSubmit(values)}
          />
        </>
      ) : (
        <Success data={LetsTalkFormData} />
      )}
    </div>
  );
};

export default RequestDemoForm;
