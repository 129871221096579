const useMoreArticles = ({
  tag,
  id,
  allPosts
}) => {

  const filteredPosts = allPosts.filter(
    (post) => post.tags.nodes[0].name
  );

  const postsWithCurrentCategory = filteredPosts.filter(
    (post) => post.tags.nodes[0].name === tag
  );

  const currentPreviewPosts = postsWithCurrentCategory.filter(
    (notSamePost) => notSamePost.id !== id
  );

  const lastPostsToDisplay = currentPreviewPosts.slice(-3);

  return lastPostsToDisplay;
}

export default useMoreArticles;
