import React from "react";
import classnames from "classnames";

import Svg from "./svg";

import * as styles from "./success.module.scss";

const Success = ({ data }) => {
  const { letsTalkFormSuccessTitle, letsTalkFormSuccessSubtitle } = data;

  return (
    <div className={classnames(styles.success, "scr-txt-center")}>
      <h3 className={classnames(styles.title, "scr-h3 scr-white")}>
        {letsTalkFormSuccessTitle}
      </h3>

      <p className={classnames(styles.subtitle, "scr-reg scr-white")}>
        {letsTalkFormSuccessSubtitle}
      </p>

      <div className={classnames(styles.media)}>
        <Svg />
      </div>
    </div>
  );
};

export default Success;
