import React, { useMemo } from 'react';
import classnames from 'classnames';
import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import LetsTalkForm from 'components/Forms/LetsTalkForm';

import useOptions from 'hooks/graphql/useOptions';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './letsTalkFormContainer.module.scss';

const LetsTalkFormContainer = ({ customData }) => {
  const getStaticImage = useStaticImage();
  const options = useOptions();

  const { letsTalkForm } = options.letsTalkForm;
  const { letsTalkFormMainContent } = letsTalkForm;

  const formImage = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage(
            'temp-customer-stories/lets-talk-form-image-desktop.png'
          ).childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage(
                'temp-customer-stories/lets-talk-form-image-tablet.png'
              ).childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage(
                'temp-customer-stories/lets-talk-form-image-mobile.png'
              ).childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <section className={classnames(styles.section)}>
      <div className={classnames(styles.wrap)}>
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.head, 'scr-white')}>
            <h2 className={classnames(styles.title, 'scr-h2')}>
              {customData
                ? customData.letsTalkFormMainContentTitle
                : letsTalkFormMainContent.letsTalkFormMainContentTitle}
            </h2>

            <p className={classnames('scr-reg')}>
              {customData
                ? customData.letsTalkFormMainContentSubtitle
                : letsTalkFormMainContent.letsTalkFormMainContentSubtitle}
            </p>
          </div>

          <div className={classnames(styles.formWrap)}>
            <LetsTalkForm customData={customData} />
          </div>

          <div className={classnames(styles.picWrap)}>
            <GatsbyImage
              image={formImage}
              alt={'form memoji image'}
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsTalkFormContainer;
