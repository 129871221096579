// extracted by mini-css-extract-plugin
export var card = "cardArticle-module--card--1vSCD";
export var link = "cardArticle-module--link--3AL9s";
export var btnOff = "cardArticle-module--btnOff--4J9vr";
export var reading = "cardArticle-module--reading--3nAex";
export var title = "cardArticle-module--title--1SmCR";
export var picWrap = "cardArticle-module--picWrap--rPHhK";
export var picWrap_gradient = "cardArticle-module--picWrap_gradient--16_n1";
export var logoWrap = "cardArticle-module--logoWrap--2HF8L";
export var tagWrap = "cardArticle-module--tagWrap--3iJdo";
export var timeRead = "cardArticle-module--timeRead--3fjnP";
export var tag = "cardArticle-module--tag--ySDRO";
export var timeRead_time = "cardArticle-module--timeRead_time--18LIt";
export var hide = "cardArticle-module--hide--17d5e";