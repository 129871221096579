import React from 'react';
import classnames from 'classnames';

import Link from '@/components/Link';
import Icon from 'components/UI/Icon';
import WpImage from 'components/WPImage';

import * as styles from './cardArticle.module.scss';
import convertToSlug from 'helpers/stringToSlug';

const CardArticle = ({ data, logo = '', reading = false, btnOff = false }) => {
  const TAGS = data.tags?.nodes;
  const TITLE = data.title;
  const TIME_READ = data.seo?.readingTime;

  return (
    <Link
      to={data.uri}
      area-label="link to article"
      className={classnames(
        styles.card,
        { [styles.reading]: reading },
        { [styles.btnOff]: btnOff }
      )}
      id={`link-to-${convertToSlug(TITLE)}`}
    >
      <div
        className={classnames(styles.picWrap, {
          [styles.picWrap_gradient]: logo,
        })}
      >
        <WpImage
          src={data.featuredImage?.node?.sourceUrl}
          alt={data.featuredImage?.node?.altText}
        />

        {logo && (
          <div className={classnames(styles.logoWrap)}>
            <WpImage src={logo?.sourceUrl} alt={logo?.altText} />
          </div>
        )}
      </div>

      <div className={classnames(styles.tagWrap)}>
        {TAGS.length > 0 ? (
          <p
            key={TAGS[0]}
            className={classnames(styles.tag, 'scr-article-tag')}
          >
            <span className={classnames(`tag-${TAGS[0].slug}`, 'tag-common')}>
              {TAGS[0].name}
            </span>
          </p>
        ) : null}

        {reading && (
          <p className={classnames(styles.timeRead, 'scr-txt-up scr-blue')}>
            <span className={classnames(styles.timeRead_time)}>
              {!TIME_READ ? '' : TIME_READ + ' min read'}
            </span>
          </p>
        )}
      </div>

      <h4 className={classnames(styles.title)}>{TITLE}</h4>

      {!reading && (
        <p
          className={classnames(
            styles.link,
            { [styles.hide]: btnOff },
            'scr-btn scr-btn--light scr-blue'
          )}
        >
          Learn More
          <Icon name="arrow-right" />
        </p>
      )}
    </Link>
  );
};

export default CardArticle;
